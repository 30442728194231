<template>
  <div class="page-acceptance" v-if="$route.query.release==1">
    <!-- 日历筛选 -->
    <!-- <div
      class="flex items-center justify-center py-2.5 theme-color bg-white"
      @click="screen_date_show = true"
    >
      <van-icon class="theme-color" size="20" name="notes-o" />
      <span class="ml-1 text-sm font-medium">{{ screen_date }}</span>
    </div> -->
    
    <!-- 筛选栏 -->
    <van-sticky>
      <div class="flex items-center" style="background-color: #fff;">
        <div class="flex-1">
          <van-search maxlength="20" v-model="projectName" placeholder="搜索项目" />
        </div>
        <div class="flex flex-col items-center ml-3 mr-3 mt-3" @click="$router.go(-1)">
          <div class="icon-replay" />
          <span class="text-xs font-bold tt">返回</span>
        </div>
      </div>
      <div
        v-if="screen_date"
        class="flex items-center justify-center py-2.5 theme-color bg-white"
        @click="screen_date_show = true"
      >
        <van-icon class="theme-color" size="20" name="notes-o" />
        <span class="ml-1 text-sm font-medium">{{ screen_date }}</span>
        <van-icon name="cross" @click.stop="dateClose" style="margin-left: 10px" size="20" color="#666"/>
      </div>
      <div
        v-else
        class="flex items-center justify-center py-2.5 theme-color bg-white"
        @click="screen_date_show = true"
      >
        <van-icon class="theme-color" size="20" name="notes-o" />
        <span class="ml-1 text-sm font-medium ">日期筛选</span>
      </div>
      <div class="nav flex items-center mt-0.5 px-2.5 py-2 bg-white" style="padding-right:0">
        <div class="screen-wrap" @click="changeScreen('region')">
          <div class="flex-1 van-ellipsis">{{ screen_region.areaName }}</div>
          <van-icon name="arrow-down" color="#666" size="12" />
        </div>
        <div class="screen-wrap" @click="changeScreen('city')">
          <div class="flex-1 van-ellipsis">{{ screen_city.cityName }}</div>
          <van-icon name="arrow-down" color="#666" size="12" />
        </div>
        <div class="screen-wrap" @click="changeScreen('acceptance')">
          <div class="flex-1 van-ellipsis">{{ acceptance.name }}</div>
          <van-icon name="arrow-down" color="#666" size="12" />
        </div>
        <div class="flex flex-col  items-center ml-3 mr-3" @click="type=(type==1?2:1)">
          <div class="icon-tw" />
          <!-- <span class="text-xs font-bold tt">列表切换</span> -->
        </div>
      </div>
      
    </van-sticky>

    <!-- 楼盘列表 -->
    <List
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="list"
      @load="onLoad"
    >
      <div v-if="list.length>0&&type==1" class="mt-4 mx-2.5 flex flex-wrap">
        <div v-for="(item, index) in list" :key="index" class="huses-item" @click="Click(item)">
          <div class="houses-img-wrap">
            <img
              class="houses-img"
              :src="item.imgUrl"
            >
          </div>
          <div class="mt-2 leading-none text-sm text-center text-gray-600">
            {{ item.projectName }}
          </div>
        </div>
      </div>
      <div class="mt-4 flex flex-wrap" v-if="list.length>0&&type==2">
        <van-cell v-for="(item, index) in list" @click="Click(item)" :key="index" :title="'【'+item.areaName+'】'+'【'+item.cityName+'】'+item.projectName" is-link />
      </div>
    </List>
    <!-- 空数据状态 -->
    <div v-show="list.length==0" class="empty">
      <img
        class="empty-img"
        :src="require('assets/acceptance/empty.png')"
        alt=""
      >
      <div class="mt-7 text-sm text-gray-500">请先选择筛选条件</div>
    </div>

    <!-- 筛选弹窗 -->
    <van-popup v-model="screen_show" position="bottom">
      <van-picker
        :title="pop_screen_title"
        show-toolbar
        :columns="pop_screen_data"
        :default-index="pop_default_sreen.index"
        :value-key="screen_type=='region'?'areaName':(screen_type=='city'?'cityName':'name')"
        @confirm="screenConfirm"
        @cancel="screenCancel"
      />
    </van-popup>

     <!-- 年月筛选 -->
    <van-calendar v-model="screen_date_show" type="range" :min-date="minDate" color="rgba(99, 150, 254, 1)" :max-date="maxDate" @confirm="dateTimeConfirm" />
  </div>
</template>
<script>
import { Icon, Picker, Popup, DatetimePicker, List, Sticky, Calendar, Search, Cell } from "vant";
import { releaseAreaList, releaseCityList, projectReleaseList } from '../../api'
import score from 'components/score'
import scoreEdit from 'components/score_edit'
export default {
  name:'release',
  components:{
    [Sticky.name]: Sticky,
    [Icon.name]:Icon,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [DatetimePicker.name]:DatetimePicker,
    [Calendar.name]:Calendar,
    [Search.name]:Search,
    [Cell.name]:Cell,
    // vanImg: Image,
    comScore:score,
    scoreEdit,
    List
  },
  watch: {
    projectName() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.current = 1
        if (this.keywords!='') {
          this.projectList()
        }
        
      }, 1000);
    }
  },
  data() {
    return {
      timer: null,
      projectName: '',
      // 当前tab下标
      tab_active:0,
      // 城市列表
      city_list:[{ id:null, cityName:'城市' }],
      // 区域列表
      region_list:[{ id:null, areaName:'区域' }],

      // 当前选择的区域
      screen_region:{ id:null, areaName:'区域' },
      // 当前选择的城市
      screen_city:{ id:null, cityName:'城市' },

      //大盘区展示区
      acceptance_list: [{acceptanceType: '', name: '全部'},{acceptanceType: '1', name: '展示区'},{acceptanceType: '2', name: '大盘区'},{acceptanceType: '3', name: '样板房'}],
      
      // 当前选择的城市
      acceptance:{acceptanceType: '', name: '全部'},
      //样板房
      houses_list: [{houses: '', name: '样板房'},{houses: '1', name: '是'},{houses: '0', name: '否'}],
      //样板房
      screen_houses: {houses: '', name: '样板房'},
      // 打开筛选弹窗
      screen_show:false,
      // 筛选弹窗默认值
      pop_default_sreen:{},
      // 当前筛选的类型
      pop_screen_type:'',
      // 筛选弹窗类型
      pop_screen_title:'',
      // 筛选弹窗数据
      pop_screen_data:[],

      screen_date:'',
      months:'',
      // 年月弹窗开关
      screen_date_show:false,
      // 最小日期
      minDate:new Date('2021/01/01'),
      // 最大日期
      maxDate:new Date(),
      // 今天
      currentDate:new Date(),

      screen_type:'',
      list:[],
      loading:false,
      finished:true,
      form:{
        'acceptanceDate':null,
        'acceptanceType':null,
        'adminId':null,
        'adminName':null,
        'areaId':null,
        'childs':[],
        'cityId':null,
        'evaluation':'',
        'housesId':null,
        'id':null,
        'imgUrl':'',
        'projectId':null,
        'specialId':null,
        'state':null,
        'totalScore':null
      },
      current:1,
      start: '',
      end: '',
      type: 1
    }
  },
  created() {
    if (this.$route.query.release!=1) {
      return window.location.replace('http://jfys.chinawans.com/2022/#/release')
    }
    if ( document.getElementById("1.23452384164.123412416")) {
      document.getElementById("1.23452384164.123412416").style.display = 'none'
    }
    this.areaList()
    this.projectList()
  },
  methods:{
    //取消日期筛选
    dateClose() {
      this.start = ''
      this.end = ''
      this.screen_date = ''
      this.current = 1
      this.list = []
      this.projectList()
    },
    Click(item) {
      this.form.areaId = item.areaId
      this.form.cityId = item.cityId
      this.form.projectId = item.id
      localStorage.setItem('form', JSON.stringify(this.form))
      localStorage.setItem('projectName', item.projectName)
      var arr = item.areaName + ',' + item.cityName
      localStorage.setItem('formInfo', JSON.stringify({
        arr:arr
      }))
      this.$router.push({ path:'/release/list', query:{ page:1,acceptanceType:item.acceptanceType }})
    },
    onLoad() {
      this.projectList()
    },
    // 项目列表
    projectList() { 
      this.$toast.loading({
        forbidClick:true,
        duration:0
      })
      projectReleaseList({
        projectName: this.projectName,
        acceptanceType:this.acceptance.acceptanceType==3?'':this.acceptance.acceptanceType,
        areaId:this.screen_region.id,
        cityId:this.screen_city.id,
        houses: this.acceptance.acceptanceType==3?1:'',
        current:this.current,
        size:20,
        type:2
      }).then(res => {
        if (this.current==1) {
          this.list = []
        }
        this.current++
        this.loading = false
        var list = res.data.data.records
        if (list.length == 0) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.list = this.list.concat(list)
      }).finally(res => {
        this.$toast.clear()
      })
    },
    // 区域列表
    areaList() {
      releaseAreaList({}).then(res => {
        this.region_list = this.region_list.concat(res.data.data)
      })
    },
    // 城市列表
    cityList(id) {
      if (id) {
        releaseCityList({
          areaId: id
        }).then(res=>{
          this.city_list = this.city_list.concat(res.data.data)
        })
      }
    },
    changeScreen(screen_type) {
      this.screen_type = screen_type
      if (screen_type == 'group') {
        this.pop_screen_title = '选择集团'
        this.pop_screen_type = '集团'
        this.pop_default_sreen = this.screen_group
        this.pop_screen_data = [...this.group_list]
      }
      if (screen_type == 'region') {
        this.pop_screen_title = '选择区域'
        this.pop_screen_type = '区域'
        this.pop_default_sreen = this.screen_region
        this.pop_screen_data = [...this.region_list]
      }
      if (screen_type == 'city') {
        this.pop_screen_title = '选择城市'
        this.pop_screen_type = '城市'
        this.pop_default_sreen = this.screen_city
        this.pop_screen_data = [...this.city_list]
      }
      if (screen_type == 'acceptance') {
        this.pop_screen_title = ''
        this.pop_screen_type = '展示区||大盘区'
        this.pop_default_sreen = this.acceptance
        this.pop_screen_data = this.acceptance_list
      }
      if (screen_type == 'houses') {
        this.pop_screen_title = ''
        this.pop_screen_type = '样板房'
        this.pop_default_sreen = this.screen_houses
        this.pop_screen_data = this.houses_list
      }
      this.screen_show = true
    },
    // 点击筛选弹窗确认按钮
    screenConfirm(value, index) {
      this.list = []
      this.current = 1
      if (this.pop_screen_type == '集团') {
        this.screen_group = { label:value, index:index }
      }
      if (this.pop_screen_type == '区域') {
        this.screen_region = value
        this.city_list = [{ id: '', cityName: "城市" }]
        this.projectList()
        this.cityList(value.id)
      }
      if (this.pop_screen_type == '城市') {
        
        this.screen_city = value
        this.projectList()
      }
      if (this.pop_screen_type == '展示区||大盘区') {
        this.acceptance = value
        this.projectList()
      }
      if (this.pop_screen_type == '样板房') {
        this.screen_houses = value
        this.projectList()
      }
      this.screen_show = false
    },
    // 点击筛选弹窗取消按钮
    screenCancel() {
      this.screen_show = false
      console.log('取消')
    },
    formatDate(date) {
      var Year = date.getFullYear()
      var Month = date.getMonth()<9?'0'+(date.getMonth()+1):date.getMonth()+1
      var Day = date.getDate()<10?'0'+date.getDate():date.getDate()
      return {
        dataCn: Year + '年' + Month + '月' + Day + '日',
        dataEn: Year + '/' + Month + '/' + Day,
      };
    },
    // 点击日期弹窗确认按钮
    dateTimeConfirm(date) {
      const [start, end] = date;
      this.screen_date = `${this.formatDate(start).dataCn} - ${this.formatDate(end).dataCn}`;
      this.start = this.formatDate(start).dataEn
      this.end = this.formatDate(end).dataEn
      this.screen_date_show = false
      this.list = []
      this.current = 1
      this.projectList()
    },
    // 点击日期弹窗取消按钮
    dateTimeCancel() {
      this.screen_date_show = false
      console.log('取消')
    },

    // 格式化日期
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    }
  },
  
}
</script>
<style lang="scss" scoped>
.page-acceptance {
  min-height: 100vh;
  background-color: #f6f6f6;
  box-sizing: border-box;
  overflow: hidden;
}

.tab-text {
  position: relative;
  padding: 14px 30px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 2px;
  }
}

.tab-active {
  .tab-text {
    color: #6396fe;
    &:before {
      background-color: #6396fe;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 64px 20px;
  &-img {
    width: 214px;
  }
}

.huses-item {
  box-sizing: border-box;
  padding: 8px;
  background-color: #fff;
  border-radius: 5px;
  width: calc((100% - 8px) / 2);
  margin-bottom: 8px;
  margin-left: 8px;
  &:nth-child(2n + 1) {
    margin-left: 0;
  }
}
.houses-img-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.houses-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.icon-replay {
  width: 14px;
  height: 14px;
  background-image: url("../../assets/acceptance/replay.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.nav{
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 10;
  margin-top: 0;
}
.list{
  // margin-top: 60px;
}
// .screen-wrap{
//   min-width: calc((100% - 2.25rem) / 3.4);
//   max-width: calc((100% - 2.25rem) / 3.4);
// }
.screen-wrap {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: auto;
  max-width: auto;
  padding: 6px 10px;
  border: 1px solid rgba(#000, 0.2);
  color: #666;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  box-sizing: border-box;
  & + .screen-wrap {
    margin-left: 12px;
  }
}
.icon-tw {
    width: 24px;
    height: 24px;
    background-image: url("../../assets/tw.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.van-search{
  padding-bottom: 0;
}
.van-search .van-cell{
  padding: 0;
}
</style>
